import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";


//
export const loadAppListApi = (params) =>ajaxGet({ url: `client/app/list`, params });

export const addAppApi = (params) =>ajaxPost({ url: `client/app/add`, params });
export const editAppApi = (params) =>ajaxPost({ url: `client/app/edit`, params });
export const manageAppStatusApi = (params) =>ajaxPost({ url: `client/app/manage/status`, params });
export const resetAppPassworodApi = (params) =>ajaxPost({ url: `client/app/reset/password`, params });
  